import site from './children/site';
// import board from './board.js'
export default {
  name: 'settings',
  path: '/settings',
  component: () => import('@/views/member/settings'),
  redirect: {
    name: 'Site'
  },
  meta: {
    layout: 'default',
    isActive: false,
    i18n: 'nav.manageSite',
    text: '사이트 관리',
    code: 'LV090000'
  },
  children: [
    site,
    // {
    //   name: 'Level',
    //   path: '/settings/level',
    //   component: () => import('@/views/member/settings/pages/level'),
    //   redirect: {
    //     name: 'LevelBankLevel'
    //   },
    //   meta: {
    //     menuMember: true,
    //     i18n: 'tabList.configLevels',
    //     text: '레벨별 설정',
    //     isActive: false,
    //     code: 'LV090300'
    //   },
    //   children: [
    //     {
    //       name: 'LevelBankLevel',
    //       path: '/settings/level/bank',
    //       component: () => import('@/views/member/settings/pages/level/bankLevel'),
    //       meta: {
    //         menuMember: true,
    //         i18n: 'tabList.configLevels',
    //         text: '계좌등급',
    //         isActive: false,
    //         code: 'LV090301'
    //       }
    //     }
    //   ]
    // },
    {
      name: 'boardMessage',
      path: '/board/message',
      component: () => import('@/views/member/board/pages/message'),
      meta: {
        menuMember: true,
        i18n: 'tabList.manageMessage',
        text: '쪽지 관리',
        code: 'LV100100'
      }
    },
    {
      name: 'boardQna',
      path: '/board/qna',
      component: () => import('@/views/member/board/pages/qna'),
      meta: {
        menuMember: true,
        i18n: 'tabList.csCenter',
        text: '문의 관리',
        code: 'LV100300'
      },
      redirect: { name: 'boardQnaList' },
      children: [
        {
          name: 'boardQnaList',
          path: '/board/qna/list',
          component: () => import('@/views/member/board/pages/qna/list'),
          meta: {
            menuMember: true,
            text: '문의목록',
            code: 'LV100600'
          }
        },
        {
          name: 'BoardAnswerList',
          path: '/board/answerList',
          component: () => import('@/views/member/board/pages/qna/answerList'),
          meta: {
            menuMember: true,
            text: '문의답변목록',
            code: 'LV100600'
          }
        }
      ]
    },
    {
      name: 'boardNotice',
      path: '/board/notice',
      component: () => import('@/views/member/board/pages/notice'),
      meta: {
        menuMember: true,
        i18n: 'tabList.notice',
        text: '공지사항',
        code: 'LV100400'
      }
    },
    {
      name: 'SiteEvent',
      path: '/settings/event',
      component: () => import('@/views/member/settings/pages/event'),
      meta: {
        menuMember: true,
        i18n: 'tabList.siteEvent',
        isActive: false,
        code: 'LV090101'
      },
      redirect: { name: 'SiteEventList' },
      children: [
        {
          name: 'SiteEventList',
          path: '/settings/event/list',
          component: () => import('@/views/member/settings/pages/event/eventList'),
          meta: {
            menuMember: true,
            text: '계좌등급',
            isActive: false,
            code: 'LV090301'
          }
        },
        {
          name: 'SiteEventPoint',
          path: '/settings/event/point',
          component: () => import('@/views/member/settings/pages/event/point'),
          meta: {
            menuMember: true,
            text: '포인트 보너스',
            isActive: false,
            code: 'LV090301'
          }
        }
      ]
    },
    {
      name: 'Acclog',
      path: '/settings/account/log',
      component: () => import('@/views/member/settings/pages/account/log'),
      meta: {
        menuMember: true,
        i18n: 'tabList.log',
        text: '로그',
        isActive: false,
        code: 'LV090102'
      }
    },
    {
      name: 'AccBalance',
      path: '/settings/account/balance',
      component: () => import('@/views/member/settings/pages/account/balance'),
      meta: {
        menuMember: true,
        i18n: 'tabList.mypage',
        text: '마이페이지',
        isActive: false,
        code: 'LV090101'
      }
    }
  ]
};
