<template>
  <div class="defaultLayout">
    <in-nav />
    <div class="_container">
      <in-header />
      <in-main :currentMenu="currentMenu" />
    </div>
    <loading></loading>
    <modal></modal>
  </div>
</template>

<script>
import InHeader from '@/components/main/header';
import InNav from '@/components/main/Nav';
import InMain from '@/components/main/Main';
import Loading from '@/components/common/Loading.vue';
import Modal from '@/components/modal';
import store from '@/store';
export default {
  name: 'DefaultLayout',
  components: {
    InHeader,
    InNav,
    InMain,
    Loading,
    Modal
  },
  computed: {
    reloadKey() {
      return store.getters.pageReloadKey;
    }
  },
  data() {
    return {
      menuList: [],
      currentMenu: {
        isActive: true,
        parent: {},
        child: {}
      }
    };
  },
  watch: {},
  async created() {
    await this.setGameList();
  }
};
</script>

<style scoped>
._container {
  width: calc(100% - 220px);
  height: 100%;
  min-width: 1450px;
  /* display: flex;
  flex-direction: column;
  flex-grow: 1; */
}
._container.close {
  width: 100%;
}
</style>
